import { Highlight, SignalWifiStatusbarConnectedNoInternet4Sharp } from '@mui/icons-material';
import React, { useEffect, useState, Text } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './annotations.css';
import styled from 'styled-components';
import { Button } from '@mui/material';

const Root = styled.div`
  position: absolute;
  top: ${props => props.top};
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: #F0F0F0;
  transition: top 1s;
  z-index: 9999;
  border: solid 1px #4B4D4F;
  border-radius: 10px;
  `

const Body = styled.div`
  position: abosolute;
  top: 50px;  
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  overflow: auto;
  `

const PageHolder = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 816px;
  `

function PDFViewer({ file, visible, onClose, evidenceLocation, careGapData, evidenceClicked, onPageCountChange }) {
  const [numPages, setNumPages] = useState(null);
  const [filename, setFilename] = useState();
  const [top, setTop] = useState(visible ? 0 : window.screen.height);
  const [pageData, setPageData] = useState(evidenceLocation);
  const [highlightData, setHighlightData] = useState([]);

  let drawnBoxes = [];

  function onDocumentLoadSuccess({ numPages }) {
    onPageCountChange(numPages);
    setNumPages(numPages);
  }

  function documentRendering() {
    setTimeout(drawAllBoxes, 500);
  }

  const createHightlightData = () => {
    let normalizedData = [];

    if (!careGapData) return;

    var careGapResult = careGapData?.care_gap_result;

    careGapResult?.map(data => {
      let id = data?.measure_name || '';

      let ndata = {
        id: id,
        evidence: data?.evidence || null,
      }

      data?.evidence.map((evidence) => {
        evidence.boxes = [];
        evidence.location.map(locations => {
          if (locations?.polygon) {
            let x = locations?.polygon[0][0] || 0;
            let y = locations?.polygon[0][1] || 0;
            let x1 = locations?.polygon[2][0] || 0;
            let y1 = locations?.polygon[2][1] || 0;
            let page = locations?.page_number;

            let highlightArea = {
              x: x,
              y: y,
              width: x1 - x,
              height: y1 - y,
              page: page
            }
            evidence.boxes.push(highlightArea);
          }
        })
      })
      normalizedData.push(ndata);
    })

    setHighlightData(normalizedData);
  }

  const hasBeenDrawn = (box) => {
    return drawnBoxes.find(db => {
      return db.x === box.x
        && db.y === box.y
        && db.width === box.width
        && db.height === box.height
        && db.page === box.page
    })
  }

  const drawAllBoxes = () => {
    clearHighlights();
    drawnBoxes = [];

    highlightData.forEach(careGap => {
      careGap.evidence.forEach(evidence => {
        evidence.careGap = careGap.id;
        drawBoxes(evidence);
      })
    });

  }

  const clearHighlights = () => {
    const annotations = Array.from(document.querySelectorAll(".react-pdf__Page__annotations.annotationLayer"));
    annotations.map(div => div.textContent = '');
  }

  const drawBoxes = (evidence, type) => {
    if (!Array.isArray(evidence.boxes)) return;
    const annotations = Array.from(document.querySelectorAll(".react-pdf__Page__annotations.annotationLayer"));

    evidence.boxes.map(box => {
      if (!box) return;

      const drawnBox = hasBeenDrawn(box);

      if (drawnBox) {
        drawnBox.detailElement.innerHTML += '<br/><br /><b>Care Gap:</b>' + evidence.careGap + '<br/><b>Evidence:</b> ' + evidence.text;
        return;
      }

      if (box.page > 0 && annotations.length >= box.page) {
        const annote = annotations[box.page - 1];

        let highlighting = document.createElement('div');
        highlighting.className = (type || '') + ' highlighting';
        highlighting.style.top = ((box.y || 0) * 96) - 2 + 'px';
        highlighting.style.left = ((box.x || 0) * 96) - 2 + 'px';
        highlighting.style.width = ((box.width || 0) * 96) + 2 + 'px';
        highlighting.style.height = ((box.height || 0) * 96) + 2 + 'px';
        // highlighting.onclick = () => { highlighting.className.includes('selected') ? highlighting.classList.remove('selected') : highlighting.classList.add('selected'); }

        let details = document.createElement('div');
        details.className = 'details';
        details.style.top = ((box.y || 0) * 96) + 14 + 'px';
        details.style.left = ((box.x || 0) * 96) - 1 + 'px';
        details.style.width = '320px';
        details.style.height = '7em';
        details.innerHTML = '<b>Care Gap:</b>' + evidence.careGap + '<br/><b>Evidence:</b> ' + evidence.text;

        annote.appendChild(highlighting);
        annote.appendChild(details);

        drawnBoxes.push({ ...box, detailElement: details })
      }
    })
  }

  useEffect(() => {
    setFilename(file);
    setTop(visible ? 0 : window.screen.height);
  }, [file])

  useEffect(() => {
    createHightlightData();
  }, [careGapData])

  useEffect(() => {
    drawAllBoxes();
  }, [highlightData])

  useEffect(() => {
    setPageData(evidenceLocation);
    let root = document.getElementById("PDFViewerRoot");
    let pixelScroll = evidenceLocation;
    if (root) {
      root.scrollTo({ top: pixelScroll, behavior: 'smooth' })
    }

    console.log(evidenceClicked)
    drawAllBoxes();
    drawnBoxes = [];
    drawBoxes(evidenceClicked, "notice");
  }, [evidenceClicked])

  return (
    <Root top={top + 'px'} id='PDFViewerRoot'>
      <Body>
        <Document
          file={filename}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={"Loading..."}
          externalLinkRel="_blank"
          externalLinkTarget="_blank"
          renderAnnotationLayer={false}
        >
          {
            [...Array(numPages)].map((e, i) => {
              return (
                <PageHolder key={i}>
                  <Page
                    pageNumber={i + 1}
                    width={(window.screen.width > 816)
                      ? 816
                      : window.screen.width
                    }
                    renderTextLayer={false}
                    renderAnnotationLayer={true}
                    onRenderSuccess={documentRendering}
                  />
                </PageHolder>
              )
            }
            )}
        </Document>
      </Body>
    </Root>
  );
}

export default PDFViewer;