import React, { useState, useEffect } from "react";
import WorkflowPanel from "./WorkflowPanel";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { getBlobToken } from "../../services/Endpoints";

const Root = styled.div``;

function Workflow() {
  const [file, setFile] = useState(null);
  const { state } = useLocation();
  const [fileID, setFileID] = useState(state?.patientData.file_id);

  const fetchdata = () => {
    if (!state.patientData) return;
    var callFile = state.patientData?.file_id;
    if (fileID) {
      callFile = fileID;
    }
    getBlobToken(callFile, setFile);
  };

  useEffect(() => {
    fetchdata();
  }, [fileID]);

  return (
    <Root>
      {file && (
        <WorkflowPanel
          file={file}
          fileID={fileID}
          setFileID={setFileID}
        />
      )}
    </Root>
  );
}

export default Workflow;
