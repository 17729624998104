import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION;
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    // instrumentationKey: "1d1drm04rdyioo07fsgi1z2qjjk6c7e4od5zf6tb",
    // connectionString: "52831fef-fa3b-40de-b463-408504b40b98",
    connectionString: process.env.REACT_APP_APPINSIGHT_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
