import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    display: flex;
    flex-direction: row;
    background-color: white;
    min-width: 900px;
    overflow: auto;    
    background: transparent;
    padding: 32px 24px 24px 0px;
    transition: left 0.25s;

    margin: -74px 0px 0 0px;

    height: 18px;
`

const Copyright = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #323334;

    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
`

const Links = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #323334;

    font-family: "Optum Sans", Helvetica, Arial;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    decoration: underline;

    & a {
      color: #003A8D;
    }

    & a:active {
      color: #0C55B8;
    }
  
    & a:hover {
      color: #003A8D;
      text-decoration: none;
    }
`

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #4B4D4F;
  padding: 0 8px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  `

function Footer() {
  return (
    <Root>
      <Copyright>
        © 2024 Optum, Inc. All Rights Reserved
      </Copyright>
      <Divider>|</Divider>
      <Links>
        <a href='mailto:chartai_support@optum.com'>Contact Us</a>
      </Links>
    </Root>
  );
}

export default Footer;
