import React, { useState, useEffect } from "react";
import PDFPanel from "./PDFPanel";
import CareGapsPanel from "./CareGapPanel";
import styled from "styled-components";
import { getAnalysisData, lockPatientWorkflow } from "../../services/Endpoints";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import warningIcon from "../../assets/warning_icon.svg";

const Root = styled.div`  
  position: absolute;  
  top: ${({ showBanner }) => (showBanner ? "48px" : "0")};  
  bottom: 20px;  
  left: 0px;  
  right: 0px;  
`;

const Banner = styled.div`    
  display: flex;  
  justify-content: space-between;    
  align-items: center;   
  background-color: #FEF9EA;    
  position: absolute;    
  border: 1px solid #826100;    
  top: -50px;    
  left: 0px;    
  right: 0;    
  z-index: 1000;    
  line-height: 24px;  
  font-size: 16px;    
  padding-left: 38px;    
  padding-top: 8px;    
  padding-bottom: 8px;    
  font-weight: 400;  
  color: #323334;  
  flex-wrap: wrap;
`;

const TextContainer = styled.div`  
  display: flex;  
  align-items: center;  
  flex: 1;
  width: calc(100% - 145px);
`;

const WarningIcon = styled.img`  
  margin-right: 10px;
`;

const Text = styled.span`  
  left: 90px;  
  white-space: pre-wrap;  
  margin-right: 16px;  
`;

const ContentWrapper = styled.div`  
  left: 0;  
  right: 0;  
`;
function WorkflowPanel({ file, fileID, setFileID }) {
  const [newLocation, setNewLocation] = useState();
  const [evidenceClicked, setEvidenceClicked] = useState(0);
  const [careGapData, setCareGapData] = useState();
  const location = useLocation();
  const [rowData, setRowData] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const closeBanner = () => {
    setShowBanner(false);
  };
  const currentUser = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));

  useEffect(() => {
    if (fileID) {
      window.sessionStorage.setItem("workflowPanelFileID", fileID);
      window.sessionStorage.setItem("workflowPanelCurrentUser", JSON.stringify(currentUser));
      getAnalysisData(fileID, (data) => {
        setCareGapData(data);
        setRowData((prevRowData) => ({
          ...prevRowData,
          in_review: data.in_review,
          review_by: data.review_by
        }));

        const currentUser = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
        const bannerStatus = data.in_review === true;
        setShowBanner(bannerStatus);
      });
    }
  }, [fileID]);

  useEffect(() => {
    if (rowData) {
      const currentUser = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
      window.sessionStorage.setItem("initialUser", JSON.stringify(currentUser));

      if (rowData.in_review === false || rowData.in_review === undefined) {
        lockPatientWorkflow(fileID, true, currentUser, (response) => {
          if (response) {
            console.log(response);
            setRowData((prevRowData) => ({ ...prevRowData, in_review: true }));
            window.sessionStorage.setItem("inReview", JSON.stringify(true));
            window.sessionStorage.setItem("apiCalledOnEnter", JSON.stringify(true));
          } else {
            console.log("Error in locking patient");
          }
        });
      }
    }
  }, [rowData, fileID]);

  useEffect(() => {
    return () => {
      if (fileID && JSON.parse(window.sessionStorage.getItem("apiCalledOnEnter"))) {
        const currentUser = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
        lockPatientWorkflow(fileID, false, currentUser, (response) => {
          if (response) {
            console.log(response);
            setRowData((prevRowData) => ({ ...prevRowData, in_review: false }));
            window.sessionStorage.setItem("inReview", JSON.stringify(false));
            setShowBanner(false);
            window.sessionStorage.setItem("apiCalledOnEnter", JSON.stringify(false));
          } else {
            console.log("Error in unlocking patient");
          }
        });
      }
    };
  }, [fileID]);

  useEffect(() => {
    const handleTabClose = event => {
      const currentUser = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
      const apiCalledOnEnter = JSON.parse(window.sessionStorage.getItem("apiCalledOnEnter"));
      const inReview = JSON.parse(window.sessionStorage.getItem("inReview"));

      if (apiCalledOnEnter && inReview) {
        lockPatientWorkflow(fileID, false, currentUser, (response) => {
          if (response) {
            console.log(response);
            window.sessionStorage.setItem("inReview", JSON.stringify(false));
            setShowBanner(false);
          } else {
            console.log("Error in unlocking patient");
          }
        });
      }
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [fileID]);

  return (
    <Root showBanner={showBanner}>
      <div>
        {showBanner && (
          <Banner>
            <TextContainer>
              <WarningIcon src={warningIcon} alt="Warning" />
              <Text>
                Someone else is viewing this file and may be editing care gaps. Any
                changes they make will not be reflected until they leave the page.
              </Text>
            </TextContainer>
            <Button
              variant="outlined"
              onClick={closeBanner}
              sx={{
                backgroundColor: "#FFFFFF",
                fontFamily: "Optum Sans, Helvetica, Arial",
                color: "#323334",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "16px",
                width: "85px",
                textTransform: "none",
                borderRadius: "18px",
                borderColor: "#323334",
                marginRight: "24px",
                paddingTop: "8px",
                paddingBottom: "8px",
                '&:hover': {
                  backgroundColor: "#F4F4F4",
                  borderColor: "#000000",
                  color: "#000",
                },
              }}
            >
              Close
            </Button>
          </Banner>
        )}
        <ContentWrapper showBanner={showBanner}>
          <PDFPanel
            file={file}
            setFileID={setFileID}
            newLocation={newLocation}
            careGapData={careGapData}
            evidenceClicked={evidenceClicked}
          />
          <CareGapsPanel
            setNewLocation={setNewLocation}
            setEvidenceClicked={setEvidenceClicked}
            careGapData={careGapData}
          />
        </ContentWrapper>
      </div>
    </Root>
  );
}

export default WorkflowPanel;