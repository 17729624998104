import React from 'react';
import "../styles.css";

const TotalCharts = (props) => {
  const defaultGradient =
    "linear-gradient(to right, #E5FDF7, #E8F4FF)";
  const gradient = props.gradient || defaultGradient;

  return (
    <div
      style={{
        width: "66px",
        height: "60%",
        borderRadius: "20px",
        background: gradient,
        padding: "10px",
      }}
    >
      <div style={{ width: "50px", height: "30px" }}>
        {props.icon}
      </div>
      <span className="widgetLabel" style={{ fontWeight: 700, fontSize: 15 }}>{props.cardName}</span>
      <div style={{ marginTop: "10px", fontSize: 20, fontWeight: 700, color: "#002677" }}>
        {props.totalCharts}
      </div>
    </div>
  );
};

export default TotalCharts;  
