import axios from '../interceptors/interceptors';
import { getDemoFlag } from './utils';

const getActiveDocuments = (callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_getPatientList, {
    "fileId": "",
    "loadStartDate": "",
    "loadEndDate": "",
    "demo_only": getDemoFlag()
  })
    .then(response => callback(response.data))
    .catch(err => {
      console.log(err);
      callback(err);
    })
}

const getFailedDocuments = (callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_getFailedDocs, {
    date_range_start: "",
    date_range_end: "",
    "demo_only": getDemoFlag()
  })
    .then(response => callback(response.data))
    .catch(() => callback(null))
}

const getFailedCount = (callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  getFailedDocuments((data) => {
    const unchecked = data ? (data.filter(data => data.action?.toLowerCase().includes('select'))).length : 0;
    callback(unchecked || 0);
  })
}

const getArchivedDocuments = (callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_getArchiveData, {
    date_range_start: "",
    date_range_end: "",
    file_type: "pdf",
    patient_name: "",
    "demo_only": getDemoFlag()
  })
    .then(response => callback(response.data))
    .catch(() => callback(null))
}

const updateFailedDocument = (data, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_failedDocUpload, data)
    .then(response => callback(response.data))
    .catch(() => callback(null))
}

const getStoredPDF = (data, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_pdfOpener,
    data,
    { responseType: 'blob' }
  )
    .then(response => callback(response))
    .catch(() => callback(null))
}

const getChartSummary = (startDate, endDate, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_generate_chart_summary, {
    "StartDate": startDate,
    "EndDate": endDate,
  })
    .then(response => callback(response.data.chart_summary))
    .catch(() => callback({
      processed: 0,
      failed: 0,
      duplicate: 0,
      archived: 0,
    }))
}

const getFeedbackSummary = async (startDate, endDate, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_generate_feedback_summary, {
    "StartDate": startDate,
    "EndDate": endDate,
  })
    .then(response => callback(response.data.feedback_summary))
    .catch(() => callback([]));
};


const generateUserLogs = (formattedStartDate, formattedEndDate, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_generateUserLogs, {
    StartDate: formattedStartDate,
    EndDate: formattedEndDate,
    UserId: "",
    SortOrder: "desc",
  })
    .then(response => callback(response.data))
    .catch(() => callback([]))
}

const sendFeedback = (data) => {
  axios.post(process.env.REACT_APP_submitFeedback, {
    page_id: data.page_id,
    file_id: data.file_id,
    care_gap_name: data.care_gap_name,
    feedback_type: data.feedback_type,
    user: data.user,
    first_name: data.first_name,
    last_name: data.last_name,
    analysis: data.analysis,
    evidence: data.evidence,
    recommendation: data.recommendation,
    actual: data.actual,
    comment: data.comment,
    thumbs_up_down: data.thumbs_up_down,
    category: data.category
  })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const updateGapStatus = (file_id, measure_id, gap_status) => {
  axios.post(process.env.REACT_APP_updateGapStatus, {
    file_id: file_id,
    measure_id: measure_id,
    gap_status: gap_status
  })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const getBlobToken = (file_id, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_generateBlobToken,
    {
      file_id: file_id,
    })
    .then(response => callback(response.data))
    .catch(err => { console.log(err) })
}

const getAnalysisData = (file_id, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.post(process.env.REACT_APP_getAnalysisData,
    {
      file_id: file_id,
    })
    .then(response => {
      console.log('RAW ANALYSIS DATA: ', response.data)
      callback(response.data)
    })
    .catch(err => { console.log(err) })
}

const updateArchiveData = (data) => {
  axios.post(process.env.REACT_APP_archiveDataUpdate,
    {
      file_id: data.file_id,
      archival_username: data.archival_username,
      archival_status: data.archival_status,
      archival_filename: data.archival_filename
    }
  )
    .then(function (response) {
      console.log(response);
    })
    .catch(function (err) { });
}

const uploadArchiveBlob = (file_id, file_name) => {
  axios.post(process.env.REACT_APP_archivedChartsBlobUpload,
    {
      file_id: file_id,
      file_name: file_name
    }
  )
    .then(function (response) {
      console.log(response);
    })
    .catch(function (err) { });
}

const uploadChart = (file_content, file_name, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };
  axios.post(process.env.REACT_APP_inboundChartsFileUpload,
    {
      file_content: file_content,
      file_name: file_name
    }
  )
    .then(function (response) {
      console.log(response.data);
      callback(response.data)
    })
    .catch(function (err) { });
}

const lockPatientWorkflow = async (fileId, lock, reviewBy, callback) => {
  callback = callback instanceof Function ? callback : () => { };

  const url = process.env.REACT_APP_lockPatientWorkflow;

  const data = {
    file_id: fileId,
    in_review: lock,
    review_by: reviewBy
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    });

    if (response.status === 200) {
      callback(response.data);
    } else {
      console.log("Error in unlocking patient");
      callback(null);
    }
  } catch (error) {
    console.log("Error in sending request", error);
    callback(null);
  }
};

const getGapClosureReport = async (callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  axios.get(process.env.REACT_APP_getGapClosureReport)
    .then(response => callback(response.data["gap-closure-report"]))
    .catch(() => callback(null));
};


const logout = (token, callback) => {
  // callback = (callback instanceof Function) ? callback : () => { };

  // axios.get(process.env.REACT_APP_logout_url +
  //     '?id_token_hint=' + token +
  //     '&post_logout_redirect_uri=' + window.location.origin +
  //     '&state=' + Date.now()
  // )
  //     .then(response => callback(response))
  //     .catch(() => callback(null))
}

const getOHIDToken = (code, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  if (window.location.hostname === "localhost")
    return callback({
      "sub": "66f9cbff-05a3-4e18-a6d6-7a4904b8e1f4",
      "birthdate": "1970-01-01",
      "email_verified": true,
      "name": "Localhost User",
      "nickname": "Loki",
      "preferred_username": "LocalhostUser",
      "given_name": "Localhost",
      "locale": "en",
      "family_name": "User",
      "email": "junk@monkeyfighter.com",
      "OHIDAccessToken": "qwertyuiopasdfghjklzxcvbnm1234567890",
      "id_token": "eyJraWQiOiI0YjFiNTFkMC0xOGE5LTRhMDctYTMzYy0yNTUzZWEzMmNiMDYiLCJhbGciOiJQUzUxMiJ9.eyJzdWIiOiI2NmY5Y2JmZi0wNWEzLTRlMTgtYTZkNi03YTQ5MDRiOGUxZjQiLCJiaXJ0aGRhdGUiOiIxOTcwLTAxLTAxIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFtciI6WyJ0b3RwIiwic2lsZW50Il0sImlzcyI6Imh0dHBzOlwvXC9pZGVudGl0eS5ub25wcm9kLm9uZWhlYWx0aGNhcmVpZC5jb20iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJSb2JlcnRXYWxzaCIsImdpdmVuX25hbWUiOiJSb2JlcnQiLCJsb2NhbGUiOiJlbiIsImF1ZCI6IkdBSUNSMDE4NTY1TiIsImF6cCI6IkdBSUNSMDE4NTY1TiIsImF1dGhfdGltZSI6MTcwNTUwNTUyOSwibmFtZSI6IlJvYmVydCBXYWxzaCIsIm5pY2tuYW1lIjoiUm9iZXJ0IiwiZXhwIjoxNzA1NTExMzI2LCJmYW1pbHlfbmFtZSI6IldhbHNoIiwiaWF0IjoxNzA1NTExMDI2LCJlbWFpbCI6InJvYmVydF93YWxzaEBvcHR1bS5jb20ifQ.e8q7ok3SDAm5CK-hu5PQTarYzRpinWS0gF8SQuHSVjp4y5XApLWxr4_EjIDXGpcg1ezLAjO4u_p99_YdxCUN4KdD9bU52mA-F1z-xl1nlxW6mJJrqZa0p54VEAX380rJxacFKL4FUqXPCGzOiBAkTEWIQ5fTzDt6uRq-MCMSlOunvmF1Xy3SJCXP4U6e5ESQL0nI2HeUt2B9EL-hp767GMBPJrcLnJT7NaIPRqtF5xAo-6X45yoKEsrHPTKn0ifaWTP2HFlWt_bcMampk3nsX95x9sqXzrV1JfgJ_zHv4DDp7deMWgpztm5D3ZMT6p14l0-w4NCgqDnvt7VagLbJtw"
    });

  axios.get(process.env.REACT_APP_getUserInfo + code)
    .then(response => callback(response.data))
    .catch(() => callback(null))
}

const fetchSignedToken = (token, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };
  axios.post(process.env.REACT_APP_CreateSignedToken, {
    OHIDCode: token
  })
    .then((response) => {
      let success = false;
      if (response?.data) {
        success = true;
      } else if (response?.data === "") {
        success = +response.status === 200;
      }

      return callback(success);
    })
    .catch(() => callback(null))

}


const validateChartAIUser = (userId, callback) => {
  callback = (callback instanceof Function) ? callback : () => { };

  if (window.location.hostname === "localhost")
    return callback({
      "firstName": "Localhost",
      "lastName": "User",
      "id": "LocalhostUser",
      "oneHealthId": "LocalhostUser",
      "useremail": "junk@monkeyfighter.com",
      "role": "Devops Admin",
      "datasecurity": "example",
      "organization": "optum",
      "loadDate": "12-07-2023",
      "OHIDAccessToken": "qwertyuiopasdfghjklzxcvbnm1234567890"
    });

  axios.get(process.env.REACT_APP_getProfileInfo + "?oneHealthId=" + userId)
    .then(response => callback(response.data))
    .catch(() => callback(null))
}

const storeJWT = (jwt) => {
  window.sessionStorage.setItem("token", jwt);
}

const storeCredentials = (user) => {
  window.sessionStorage.setItem("UserOnehealthID", JSON.stringify(user.oneHealthId));
  window.sessionStorage.setItem("providerDetails", JSON.stringify(
    {
      apcId: user.apcId,
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
      oneHealthId: user.oneHealthId,
      useremail: user.useremail,
      role: user.role
    }
  ));
};

const getChartsProcessed = (callback) => {
  axios.post(process.env.REACT_APP_getDocsProcessed, {

  })
    .then(response => callback(response.data.data))
    .catch(err => { console.log(err) })
}

const saveProfile = async (data) => {
  const response = await axios.post(
    process.env.REACT_APP_saveProfileInfo,
    {
      firstName: data.firstName,
      lastName: data.lastName,
      id: data.oneHealthId,
      oneHealthId: data.oneHealthId,
      apcId: data.apcID,
      useremail: data.useremail,
      role: data.role,
      datasecurity: data.datasecurity,
      organization: data.organization,
      loadDate: data.loadDate
    }
  );
  return response;
}

const getProfileInfo = async () => {
  return await axios.get(process.env.REACT_APP_getProfileInfo);
}

export {
  storeJWT,
  storeCredentials,
  getActiveDocuments,
  getFailedDocuments,
  getFailedCount,
  updateFailedDocument,
  getArchivedDocuments,
  getStoredPDF,
  sendFeedback,
  updateGapStatus,
  getBlobToken,
  getAnalysisData,
  updateArchiveData,
  uploadArchiveBlob,
  uploadChart,
  fetchSignedToken,
  validateChartAIUser,
  getOHIDToken,
  logout,
  getChartsProcessed,
  saveProfile,
  getProfileInfo,
  generateUserLogs,
  getChartSummary,
  lockPatientWorkflow,
  getFeedbackSummary,
  getGapClosureReport
};
