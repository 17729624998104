import React, { useState, useEffect } from 'react';
import { getChartsProcessed, getChartSummary, getFeedbackSummary } from "../../services/Endpoints";
import "./styles.css";
import TotalCharts from "./Widgets/TotalCharts";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MyResponsivePie from './Widgets/PieChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TextField from "@mui/material/TextField";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ArchiveIcon from '@mui/icons-material/Archive';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CommentIcon from '@mui/icons-material/Comment';
import { getGapClosureReport } from '../../services/Endpoints';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Footer from '../widgets/Footer';
dayjs.extend(utc);

const AnalyticsReport = () => {

  const [numCharts, setNumCharts] = useState();
  const today = dayjs();
  const [searchTerm, setSearchTerm] = useState("");
  const sevenDaysAgo = dayjs().subtract(7, 'day');
  const [startDate, setStartDate] = useState(sevenDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(sevenDaysAgo);
  const [tempEndDate, setTempEndDate] = useState(today);
  const [feedbackSummaryData, setFeedbackSummaryData] = useState([]);
  const [gapClosureReport, setGapClosureReport] = useState(null);
  const [selectedMeasure, setSelectedMeasure] = useState("Overall");

  const SelectSmall = ({ measures, selectedMeasure, setSelectedMeasure }) => {
    const handleChange = (event) => {
      setSelectedMeasure(event.target.value);
    };

    return (
      <FormControl sx={{ m: 1, width: 360 }} size="small">
        <InputLabel id="demo-select-small-label">Measure</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedMeasure}
          label="Measure"
          onChange={handleChange}
        >
          {measures.map((measure) => (
            <MenuItem key={measure} value={measure}>
              {measure}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const fetchChartSummary = async (startDate, endDate) => {
    if (!startDate || !endDate) return;

    const formattedStartDate = dayjs(startDate)
      .hour(0)
      .minute(0)
      .second(0)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const formattedEndDate = dayjs(endDate)
      .hour(23)
      .minute(59)
      .second(59)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    await getChartSummary(formattedStartDate, formattedEndDate, setChartSummary);
  };

  const fetchFeedbackSummaryData = async (startDate, endDate) => {
    if (!startDate || !endDate) return;

    const formattedStartDate = dayjs(startDate)
      .hour(0)
      .minute(0)
      .second(0)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const formattedEndDate = dayjs(endDate)
      .hour(23)
      .minute(59)
      .second(59)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    await getFeedbackSummary(formattedStartDate, formattedEndDate, setFeedbackSummaryData);
  };

  const filterData = (data) => {
    if (!searchTerm) return data;

    return data.filter((row) => {
      const searchString = [
        row.first_name,
        row.last_name,
        row.file_id,
        row.file_name,
        ...row.feedback.map((feedbackItem) => [
          feedbackItem.feedback_type,
          feedbackItem.user,
          feedbackItem.recommendation,
          feedbackItem.actual,
          feedbackItem.comment,
          feedbackItem.thumbs_up_down,
          feedbackItem.category,
          feedbackItem.care_gap_name,
        ]),
      ].join(" ").toLowerCase();

      return searchString.includes(searchTerm.toLowerCase());
    });
  };
  const calculateFeedbackStats = () => {
    let totalFiles = 0;
    let totalFeedbacks = 0;
    let totalPositive = 0;
    let totalNegative = 0;

    feedbackSummaryData.forEach((row) => {
      totalFiles += 1;
      totalFeedbacks += row.feedback.length;
      row.feedback.forEach((feedbackItem) => {
        if (feedbackItem.thumbs_up_down === "Positive") {
          totalPositive += 1;
        } else if (feedbackItem.thumbs_up_down === "Negative") {
          totalNegative += 1;
        }
      });
    });

    return {
      totalFiles,
      totalFeedbacks,
      totalPositive,
      totalNegative,
    };
  };

  const feedbackStats = calculateFeedbackStats();

  function FeedbackRow({ row, filteredFeedback }) {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{row.first_name + " " + row.last_name}</TableCell>
          <TableCell style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{row.file_id}</TableCell>
          <TableCell style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{row.file_name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
            colSpan={9}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Type</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>User</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Recommendation</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Actual</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Comment</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Like/Dislike</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Category</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Care Gap</TableCell>
                      <TableCell style={{ fontWeight: 700, fontFamily: "Optum Sans, Helvetica, Arial" }}>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFeedback.map((feedbackItem, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.feedback_type}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.user}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.recommendation}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.actual}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.comment}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.thumbs_up_down}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.category.join(', ')}</TableCell>
                        <TableCell style={{ fontSize: "12px", borderRight: "1px solid #E5E5E6", fontFamily: "Optum Sans, Helvetica, Arial" }}>{feedbackItem.care_gap_name}</TableCell>
                        <TableCell style={{ fontSize: "12px", width: "10%", fontFamily: "Optum Sans, Helvetica, Arial" }}>{dayjs.utc(feedbackItem.timestamp).local().format("YYYY-MM-DD hh:mm A")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const tableCellStyle = {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  };

  const computeMetrics = (open_open, open_close, close_open, close_close) => {
    const total = open_open + open_close + close_open + close_close;
    const truePositive = open_open;
    const falseNegative = close_open;
    const trueNegative = close_close;
    const falsePositive = open_close;

    let accuracy = (truePositive + trueNegative) / total;
    let precision = truePositive / (truePositive + falsePositive);
    let recall = truePositive / (truePositive + falseNegative);
    let f1Score = 2 * (precision * recall) / (precision + recall);

    accuracy = isNaN(accuracy) ? "" : accuracy.toFixed(2);
    precision = isNaN(precision) ? "" : precision.toFixed(2);
    recall = isNaN(recall) ? "" : recall.toFixed(2);
    f1Score = isNaN(f1Score) ? "" : f1Score.toFixed(2);

    return {
      accuracy: accuracy === "" ? "-" : parseFloat(accuracy),
      precision: precision === "" ? "-" : parseFloat(precision),
      recall: recall === "" ? "-" : parseFloat(recall),
      f1Score: f1Score === "" ? "-" : parseFloat(f1Score)
    };
  };

  const {
    open_open,
    open_close,
    close_open,
    close_close,
  } = gapClosureReport?.[selectedMeasure]?.[0] || {};

  const metrics = computeMetrics(open_open, open_close, close_open, close_close);

  const [chartSummary, setChartSummary] = useState({
    processed: 0,
    failed: 0,
    duplicate: 0,
    archived: 0,
  });

  useEffect(() => {
    getChartsProcessed(setNumCharts);
  }, [])

  useEffect(() => {
    getGapClosureReport(setGapClosureReport);
  }, []);

  useEffect(() => {
    fetchChartSummary(startDate, endDate);
  }, []);

  useEffect(() => {
    fetchFeedbackSummaryData(startDate, endDate);
  }, []);

  const handleSearchButtonClick = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      fetchChartSummary(tempStartDate, tempEndDate);
      fetchFeedbackSummaryData(tempStartDate, tempEndDate);
    }
  };

  const pieChartData = [
    { id: "Processed", value: chartSummary.processed },
    { id: "Failed", value: chartSummary.failed },
    { id: "Duplicates", value: chartSummary.duplicate },
    { id: "Archived", value: chartSummary.archived },
  ];

  const positiveCardGradient = "linear-gradient(to right, #E5FDF7, #BCF1E5)";
  const negativeCardGradient = "linear-gradient(to right, #FFFFF0, #FFE5C7)";

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <div style={{ height: "80%", overflowX: "hidden", padding: "40px 48px" }}>
        <div style={{ padding: "10px", height: "180px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={tempStartDate}
              onChange={(newValue) => {
                setTempStartDate(newValue);
              }}
              sx={{
                marginRight: "20px", '& .MuiInputLabel-root': {
                  fontFamily: 'Optum Sans, Helvetica, Arial'
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={tempEndDate}
              minDate={tempStartDate}
              onChange={(newValue) => {
                setTempEndDate(newValue);
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: 'Optum Sans, Helvetica, Arial'
                }
              }}
            />

          </LocalizationProvider>
          <TextField
            id="outlined-basic"
            label="Find"
            variant="outlined"
            onChange={(event) => setSearchTerm(event.target.value)}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: 'Optum Sans, Helvetica, Arial'
              }
            }}
          />
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <button
              onMouseDown={() => setIsButtonClicked(true)}
              onMouseUp={() => setIsButtonClicked(false)}
              onClick={handleSearchButtonClick}
              style={{
                backgroundColor: "#002677",
                fontFamily: 'Optum Sans, Helvetica, Arial',
                color: "white",
                borderRadius: "5px",
                padding: "10px 20px",
                border: "none",
                marginLeft: "40px",
                marginTop: "9px",
                cursor: "pointer",
                transform: isButtonClicked ? "scale(0.95)" : "scale(1)",
                transition: "transform 0.1s ease",
              }}
            >
              Search
            </button>
          </div>

          <div style={{ display: "flex", gap: "15%", marginTop: "-15px", color: "#001D5B" }}>
            <h2>Charts Summary</h2>
            <h2>Feedback Summary</h2>
          </div>

          <div
            style={{ display: "flex", gap: "2%", width: "100%", height: "100%", marginTop: "-15px" }}
          >
            <div style={{ display: "flex", gap: "1%", width: "40%" }}>
              <TotalCharts icon={<span style={{ color: "#002677" }}><FactCheckIcon /></span>} totalCharts={chartSummary.processed} cardName="Processed" />
              <TotalCharts icon={<span style={{ color: "#002677" }}><FeedbackIcon /></span>} totalCharts={chartSummary.failed} cardName="Failed" />
              <TotalCharts icon={<span style={{ color: "#002677" }}><FileCopyIcon /></span>} totalCharts={chartSummary.duplicate} cardName="Duplicates" />
              <TotalCharts icon={<span style={{ color: "#002677" }}><ArchiveIcon /></span>} totalCharts={chartSummary.archived} cardName="Archived" />
            </div>
            <div style={{ display: "flex", gap: "1%", width: "40%", marginLeft: "8%" }}>
              <TotalCharts icon={<span style={{ color: "#002677" }}><InsertDriveFileIcon /></span>} totalCharts={feedbackStats.totalFiles} cardName="Files" />
              <TotalCharts icon={<span style={{ color: "#002677" }}><CommentIcon /></span>} totalCharts={feedbackStats.totalFeedbacks} cardName="Feedbacks" />
              <TotalCharts icon={<span style={{ color: "#002677" }}><ThumbUpAltIcon /></span>} totalCharts={feedbackStats.totalPositive} cardName="Positive" gradient={positiveCardGradient} />
              <TotalCharts icon={<span style={{ color: "#002677" }}><ThumbDownAltIcon /></span>} totalCharts={feedbackStats.totalNegative} cardName="Negative" gradient={negativeCardGradient} />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", gap: "2%", height: "57%" }}>
          <div style={{ height: "92%", width: "25%", border: "2px solid #E5E5E6", borderRadius: "5px", marginTop: "70px", marginLeft: "10px" }}>
            <MyResponsivePie data={pieChartData} />
          </div>
          <div style={{ gap: "10px", width: "40%", marginTop: "70px" }}>
            <TableContainer component={Paper} style={{ width: "175%", minHeight: "70%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 700, fontSize: "15px", fontFamily: "Optum Sans, Helvetica, Arial" }}>Expand</TableCell>
                    <TableCell style={{ fontWeight: 700, fontSize: "15px", fontFamily: "Optum Sans, Helvetica, Arial" }}>Name</TableCell>
                    <TableCell style={{ fontWeight: 700, fontSize: "15px", fontFamily: "Optum Sans, Helvetica, Arial" }}>File ID</TableCell>
                    <TableCell style={{ fontWeight: 700, fontSize: "15px", fontFamily: "Optum Sans, Helvetica, Arial" }}>File Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData(feedbackSummaryData).length > 0 ? (
                    filterData(feedbackSummaryData).map((row, index) => (
                      <FeedbackRow
                        key={index}
                        row={row}
                        filteredFeedback={row.feedback?.filter((feedbackItem) => {
                          const searchString = [
                            feedbackItem.feedback_type,
                            feedbackItem.user,
                            feedbackItem.recommendation,
                            feedbackItem.actual,
                            feedbackItem.comment,
                            feedbackItem.thumbs_up_down,
                            feedbackItem.category,
                            feedbackItem.care_gap_name,
                          ].join(" ").toLowerCase();

                          return searchString.includes(searchTerm.toLowerCase());
                        })}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: "center", fontFamily: "Optum Sans, Helvetica, Arial" }}>
                        No Feedbacks To Show
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <h2 style={{ marginLeft: "10px", marginTop: "70px", color: "#001D5B", marginBottom: "5px" }}>Acceptance Rate</h2>
        <div>
          <div style={{ display: "flex", gap: "1%", width: "40%", marginLeft: "10px", marginBottom: "5px" }}>
            <TotalCharts icon={<span style={{ color: "#002677" }}><QueryStatsIcon /></span>} totalCharts={metrics.accuracy} cardName="Accuracy" />
            <TotalCharts icon={<span style={{ color: "#002677" }}><QueryStatsIcon /></span>} totalCharts={metrics.precision} cardName="Precision" />
            <TotalCharts icon={<span style={{ color: "#002677" }}><QueryStatsIcon /></span>} totalCharts={metrics.recall} cardName="Recall" />
            <TotalCharts icon={<span style={{ color: "#002677" }}><QueryStatsIcon /></span>} totalCharts={metrics.f1Score} cardName="F1 Score" />
          </div>
          <SelectSmall
            measures={Object.keys(gapClosureReport || {})}
            selectedMeasure={selectedMeasure}
            setSelectedMeasure={setSelectedMeasure}
          />
          <div style={{ marginBottom: 150 }}>
            {gapClosureReport && (
              <div style={{ width: "25%", marginLeft: "10px" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell colSpan={2} align="center" style={tableCellStyle}><b>Business Predictions</b></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={tableCellStyle}><b>GPT Predictions</b></TableCell>
                        <TableCell style={tableCellStyle}>Open</TableCell>
                        <TableCell style={tableCellStyle}>Close</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={tableCellStyle}>Open</TableCell>
                        <TableCell style={tableCellStyle}>{gapClosureReport[selectedMeasure][0].open_open}</TableCell>
                        <TableCell style={tableCellStyle}>{gapClosureReport[selectedMeasure][0].open_close}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={tableCellStyle}>Close</TableCell>
                        <TableCell style={tableCellStyle}>{gapClosureReport[selectedMeasure][0].close_open}</TableCell>
                        <TableCell style={tableCellStyle}>{gapClosureReport[selectedMeasure][0].close_close}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </div>
        <div>

        </div>
      </div>
      <div style={{ marginTop: '40px', marginLeft: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default AnalyticsReport;