import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
// import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useLocation } from "react-router-dom";
import { ReactComponent as Alert } from "../../assets/Alert.svg";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

const ModalTitle = styled.div`
  color: var(--primary-100, var(--Main-brand-primary, #002677));
  text-align: center;
  align-self: stretch;
  margin-top: 16px;

  /* mobile/heading/3 */
  font-family: Optum Sans, Helvetica, Arial;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 123.077% */
`

const ModalText = styled.div`
  color: var(--neutral-90, #323334);
  text-align: center;
  align-self: stretch;

  /* mobile/body/1/a */
  font-family: Optum Sans, Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-top: 8px;
`

const Footer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const LogoutBox = styled.div`
  display: flex;
  height: 30px;
  min-width: 48px;
  max-width: 343px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 50px;
`

const LogoutText = styled.div`
  color: var(--primary-70, #0C55B8);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* mobile/body/1/c */
  font-family: Optum Sans, Helvetica, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
`

const ErrorScreen = ({ unknownUser, onlySmallScreen }) => {


  const handleLogout = (event) => {
    // logoutTracking();
    const token = window.sessionStorage.getItem("token")
    window.sessionStorage.clear();
    
    const logoutUrl = "https://" + process.env.REACT_APP_logout_url + "?id_token_hint=" + token + '&post_logout_redirect_uri=' + process.env.REACT_APP_OHID_LogoutURLRedirect + '&state=' + Date.now();
    if(token === null){

      window.location.replace(window.location.origin);
    }
    // console.log("Logout: ", logoutUrl);
    else window.location.replace(logoutUrl);
  };
  //   const logoutTracking = () => {
  //     console.log("log timer");
  //     appInsights.trackEvent({
  //       name: "customLogs",
  //       properties: {
  //         userid: providerDetails.id,
  //         type: "logout",
  //         file: "AppLayout",
  //         role: providerDetails.role,
  //         apcId: providerDetails.apcId,
  //       },
  //     });
  //   };

  return (
    <div>
      <Modal
        style={{
          width: "90%",
          height: "65%",
          backgroundColor: "white",
          marginLeft: onlySmallScreen ? "4.5%" : "7%",
          marginRight: "93%",
          marginTop: "auto",

          //   marginRight: "auto",
          //   margin: "auto",
        }}
        open={unknownUser}
      >
        <div
          style={{
            textAlign: "center",
            width: "90%",
            padding: "16px 20px",
            backgroundColor: "white",
            borderRadius: "16px",
            display: "absolute",
          }}
        >
          <div
            style={{
              marginTop: "24px",

              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Alert />
          </div>
          <ModalTitle>Account Not Verified</ModalTitle>
          <ModalText>
            Your account needs to be verified before you can view this
            application. Please contact us at
            <br />
            <span
              style={{
                color: "#0C55B8",
                cursor: "pointer",
                textDecorationLine: "underline",
                fontFamily: "Optum Sans, Helvetica, Arial",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
              }}
              onClick={() => window.location.href = "mailto:chartai_support@optum.com"}
            >
              chartai_support@optum.com
            </span>
            .
            <br />
            <p></p>
            We apologize for any inconvenience.
          </ModalText>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "#0C55B8",
              fontFamily: "Optum Sans, Helvetica, Arial",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "20px",
              marginBottom: "16px",
            }}
            onClick={handleLogout}
          >
            <span style={{ marginRight: "8px", fontWeight: 700 }}>Log Out</span>
            <LogoutIcon style={{ height: "16px", width: "16px" }} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorScreen;
