import LogoPanel from "./LogoPanel";
import MenuPanel from "./MenuPanel";
import styled from "styled-components";

const Root = styled.div`
  position: fixed;
  top: 0;
  height: 111px;
  left: 0;
  right: 0;
  padding: 0 40px;
  display: flex;
  min-width: 508px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 2px 6px 0px rgba(25, 25, 26, 0.16);
  background-color: #FAFAFA;
`;

function Header({ userDetails }) {
    return (
        <Root>
            <LogoPanel />
            <MenuPanel userDetails={userDetails} />
        </Root>
    );
}

export default Header;
