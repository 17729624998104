import { ResponsiveLine } from "@nivo/line";

const MyResponsiveLine = ({ data, axisBottom }) => (
  <ResponsiveLine
    data={data}
    animate={false}
    margin={{ top: 20, right: 140, bottom: 65, left: 45 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      // stacked: true,  
      reverse: false,
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      ...axisBottom,
      format: (value) => value,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "login count",
      legendOffset: -40,
      legendPosition: "middle",
    }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    useMesh={true}
    colors={(serie) => {
      switch (serie.id) {
        case "Devops Admin":
          return "#15A796";
        case "Quality Coordinator":
          return "#C72887";
        case "Demo User":
          return "#155C8E";
        case "Client Manager":
          return "#8061BC";
        default:
          return "yellow";
      }
    }}
    legends={[
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export default MyResponsiveLine;
