import React from 'react';
import styled, { keyframes } from 'styled-components';
import Button from "@mui/material/Button";
import checkIcon from "../../assets/check-circle.svg";

const fadeIn = keyframes`  
  from {  
    opacity: 0;  
  }  
  to {  
    opacity: 1;  
  }  
`;

const fadeOut = keyframes`  
  from {  
    opacity: 1;  
  }  
  to {  
    opacity: 0;  
  }  
`;

const ModalBackground = styled.div`  
  position: fixed;  
  top: 124px;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  display: flex;  
  justify-content: flex-end;  
  z-index: 10;  
  animation: ${props => props.isOpen ? fadeIn : fadeOut} 0.5s forwards;  
`;

const ModalContainer = styled.div`  
  background-color: #EFF6EF;  
  font-family: "Optum Sans", Helvetica, Arial;  
  font-weight: 400;  
  font-size: 16px;  
  line-height: 24px;  
  padding: 16px;  
  border-radius: 8px;  
  border: 1px solid #007000;  
  width: 450.4px;  
  height: 46.4px;  
  position: relative;  
  color: #4B4D4F;  
  margin-right: 40px;  
  display: flex;  
  align-items: center;  
  justify-content: space-between;  
  padding-left: 16px;  
  padding-right: 16px;
`;

const ContentContainer = styled.div`  
  display: flex;  
  align-items: center;  
  margin-right: 16px;
`;

const CheckIcon = styled.img`  
  margin-right: 10px;  
  width: 18px;  
  height: 18px;  
`;

const StyledButton = styled(Button)`  
  && {  
    background-color: #FFFFFF;  
    color: #323334;  
    font-family: Optum Sans, Helvetica, Arial;  
    font-size: 14px;  
    font-weight: 700;  
    line-height: 16px;  
    width: 85px;  
    text-transform: none;  
    border-radius: 18px;  
    border: 1px solid #323334;  
    padding: 8px;  
    &:hover {  
      background-color: #F4F4F4;  
      border-color: #000000;  
      color: #000;  
    }  
  }  
`;

const Modal = ({ isOpen, onClose, children }) => {
    const [visible, setVisible] = React.useState(isOpen);

    React.useEffect(() => {
        if (isOpen) {
            setVisible(true);
        } else {
            setTimeout(() => setVisible(false), 500);
        }
    }, [isOpen]);

    if (!visible) return null;

    return (
        <ModalBackground isOpen={isOpen}>
            <ModalContainer>
                <ContentContainer>
                    <CheckIcon src={checkIcon} alt="Check" />
                    {children}
                </ContentContainer>
                <StyledButton onClick={onClose}>
                    Close
                </StyledButton>
            </ModalContainer>
        </ModalBackground>
    );
};

export default Modal;