import React from "react";  
import { Navigate } from "react-router-dom";  

const ProtectedRoute = ({ children, allowedRoles, userRole }) => {  
  if (allowedRoles.includes(userRole)) {  
    return children;  
  } else {  
    const redirectPath = userRole === 'Client Manager' ? '/Reports' : '/Patientlist';  
    return <Navigate to={redirectPath} />;  
  }  
};  

export default ProtectedRoute;  
